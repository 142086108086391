<template>
  <!-- banner -->
  <AppBanner :url="require('@/assets/image/cover/service.png')" title="解決方案及服務" />

  <!-- nav -->
  <AppContentNav v-model="activeId" :items="navItem" />

  <section
    class="py-6 py-lg-8"
    v-for="(content, idx) in contents"
    :key="content.id"
    :class="{ 'bg-gray-100': idx % 2 === 1 }"
  >
    <div class="container">
      <div class="px-lg-8 text-center">
        <h2 class="content-title fs-1 text-center mb-5 mb-lg-8">{{ content.title }}</h2>
        <p class="fw-light mb-5">
          {{ content.brief }}
        </p>
        <button
          class="btn btn-secondary text-white rounded-0 fs-5"
          @click="$router.push(`/solution-service/${content.articleUuid}`)"
        >
          查看更多
        </button>
      </div>
    </div>
  </section>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import { SOLUTION } from "@/constants/article";
import { apiQueryUserArticle } from "@/api/article";

export default {
  name: "SolutionService",
  data() {
    return {
      activeId: 1,
      contents: [],
    };
  },
  computed: {
    ...mapGetters("category", ["getCategory"]),
    navItem() {
      const result = this.getCategory(SOLUTION.key);
      return result.map((r) => ({ ...r, path: `/solution-service?category=${r.id}` }));
    },
  },
  methods: {
    ...mapActions("category", ["queryCategory"]),
    async getArticle({ categoryId }) {
      try {
        const res = await apiQueryUserArticle({ type: SOLUTION.key, categoryId });
        const { data, code, message } = res.data;
        if (code === 200) {
          const { content } = data;
          this.contents = content;
        } else {
          this.$vHttpsNotice({ statusCode: code, title: message });
        }
      } catch (error) {
        // regardless
      }
    },
  },
  watch: {
    activeId(val) {
      if (val) {
        this.getArticle({ categoryId: val });
      }
    },
  },
  async created() {
    if (!this.navItem.length) {
      await this.queryCategory({ type: SOLUTION.key });
    }
    const { category } = this.$route.query;
    this.activeId = Number(category) || (this.navItem.length && this.navItem[0].id);
  },
};
</script>
